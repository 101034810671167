import Splide from '@splidejs/splide';

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('.splidemain') != null) {
    const thumbnails = new Splide('#thumbnail-carousel', {
      autoplay: true,
      interval: 5000,
      fixedWidth: 120,
      fixedHeight: 120,
      gap: 0,
      rewind: true,
      pagination: false,
      isNavigation: true,
      arrows: false,
      breakpoints: {
        600: {
          fixedWidth: 60,
          fixedHeight: 44,
        },
      },
    });

    const main = new Splide('#splidemain', {
      pagination: false,
    });

    main.sync(thumbnails);
    main.mount();
    thumbnails.mount();
    // const bar = splide.root.querySelector('.my-slider-progress-bar');
    // splide.on('mounted move', () => {
    //   const end = splide.Components.Controller.getEnd() + 1;
    //   bar.style.width = `${String((100 * (splide.index + 1)) / end)}%`;
    // });

    splide.mount();
  }

  if (document.querySelector('.splideyt') != null) {
    const main = new Splide('#splideyt', {
      arrows: true,
      type: 'loop',
      speed: 800,
      padding: '12rem',
      updateOnMove: true,
      breakpoints: {
        1000: {
          padding: '8rem',
        },
        800: {
          padding: '8rem',
        },
        600: {
          padding: '0',
        },
      },
    });

    main.mount();
  }
});
